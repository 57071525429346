import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./GatewaySection.scss"

const GatewaySection = (props) => {
    const garewaysLogos = props.data.imagesCollection.items;
    return (
        <div className="gateways-wrapper">
        <div className="container">
          <div className="gateways-title">powered by</div>
          <div className="gateways-icons">
            <ul>
                {garewaysLogos.map((logo, idx) => (
                    <li key={'gateway' + (idx + 1)}>
                        <LazyLoadImage effect="opacity" src={logo.url} alt="Muvin" />
                    </li>
                ))
                }
            </ul>
          </div>
        </div>
      </div>
    )
}

export default GatewaySection
