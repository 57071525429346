import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { initializePayload } from "../../../common-service";
import "./PageNotFound.scss";

const PageNotFound = () => {
  const checkBotLoading = setInterval(() => {
    if (document.getElementById("ymIframe")) {
      initializePayload("pagenotfound");
      clearInterval(checkBotLoading);
    }
  }, 200);
  const [isMobileState, setisMobileState] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.outerWidth < 768) {
        setisMobileState(true);
      } else {
        setisMobileState(false);
      }
    };
    resizeHandler();

    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <div className="w-100">
      <div className="page-not-found-wrapper">
        <div className="page-not-found-container">
          <div className="container">
            <div className="row">
              <div
                className={
                  "col-12 col-md-6 " + (isMobileState ? "order-2" : "")
                }
              >
                <div className="heading">oh no</div>
                <div className="sub-heading">something’s wrong</div>
                <div className="description">
                  We can't find the page you are looking for. Check out our Help
                  Center or head back to home.
                </div>
                <div className="button-wrapper">
                  <Link to="" className="btn btn-white">
                    <span>go back home</span>
                  </Link>
                </div>
              </div>
              <div
                className={
                  "col-12 col-md-6 " + (isMobileState ? "order-1" : "")
                }
              >
                <div className="page-not-found-text">
                  <div className="text">
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
