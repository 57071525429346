import React, { useEffect, useState } from 'react';
// import Banner from '../banner/Banner';

import Unnamed from '../../assets/images/unnamed_1.png';
// import stickyLogo from '../../assets/images/footer-logo.png';
import { getImage, getWindowWidth } from '../../common-service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AppStoreBlue from '../../assets/images/AppStoreBlue.png';
import PlayStoreBlue from '../../assets/images/GooglePlayBlue.png';
import './MigrateSteps.scss';

const MigrateSteps = () => {
  let [isMobileState, setisMobileState] = useState(getWindowWidth);

  useEffect(() => {
    function handleResize() {
      setisMobileState(getWindowWidth);
    }

    setisMobileState(getWindowWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='w-100'>
      <div className='containerBox'>
        <div className='image-container'>
          <LazyLoadImage
            effect='opacity'
            src={Unnamed}
            alt='our mission'
            className='img-fluid'
          />
        </div>
        {/* <div className='col-12 col-md-7'> */}
        <div className='content-container'>
          <div className='title-wrapper isIcon inline mb-30'>
            <span className='text'>
              Any available balances will be migrated to the Livquik app. Follow
              the steps below to access your balance
            </span>
          </div>
          <div className='content-wrapper'>
            <div className='list'>
              <div className='bullet-box'>
                <p>1</p>
              </div>
              <div className='isListTitleDescription'>
                {/* <div className='title'>fun, engaging &amp; social</div> */}
                <div className='description'>
                  <p>Install the app “QWTag” app</p>
                </div>
                <div className='cta-box'>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.livquik.app'
                    target='_blank'
                  >
                    <img src={PlayStoreBlue} />
                  </a>
                  <a
                    href='https://apps.apple.com/in/app/qwtag-zip-through-digitally/id6448750529'
                    target='_blank'
                  >
                    <img src={AppStoreBlue} />
                  </a>
                </div>
              </div>
            </div>
            <div className='list'>
              <div className='bullet-box'>
                <p>2</p>
              </div>
              <div className='isListTitleDescription'>
                {/* <div className='title'>fun, engaging &amp; social</div> */}
                <div className='description'>
                  <p>Enter your registered mobile number</p>
                </div>
              </div>
            </div>
            <div className='list'>
              <div className='bullet-box'>
                <p>3</p>
              </div>
              <div className='isListTitleDescription'>
                {/* <div className='title'>fun, engaging &amp; social</div> */}
                <div className='description'>
                  <p>
                    Set the mobile PIN and you are all set to use your account!
                  </p>
                </div>
              </div>
            </div>
            <div className='list1'>
              <div> &nbsp; &nbsp; &nbsp; </div>
              <div className='isListTitleDescription'>
                {/* <div className='title'>fun, engaging &amp; social</div> */}
                <div className='description'>
                  for urgent queries contact &nbsp;
                  <a href='#'>support@livquik.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MigrateSteps;
