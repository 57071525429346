import axios from "axios";
// import {
//   geocodeByAddress,
//   getLatLng,
//   geocodeByPlaceId
// } from 'react-places-autocomplete';

let staticData = [];

export const setStaticDataFunc = (data) => {
  if (staticData.length !== 0) {
    data.forEach((d) => {
      if (staticData.filter((f) => f.fileName === d.fileName).length === 0) {
        staticData.push(d);
      }
    });
  } else {
    data.forEach((d) => {
      staticData.push(d);
    });
  }
};

export const getStaticDataFunc = () => {
  return staticData;
};

export const getImage = (name) => {
  if (staticData.length !== 0) {
    if (staticData.filter((i) => i.fileName === name).length !== 0) {
      const imageUrl = staticData.filter((i) => i.fileName === name)[0];
      return imageUrl.url;
    }
  }
};
export const getWindowWidth = () => {
  let state = false;
  if (window.innerWidth < 767) {
    state = true;
  } else {
    state = false;
  }
  return state;
};

export const getData = async (query) => {
  let data = await axios.post(
    process.env.REACT_APP_BASE_URL,
    JSON.stringify({
      query: query,
    }), {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + process.env.REACT_APP_ACCESS_TOKEN,
      },
    }
  );

  return data;
};

export const uniformHeight = (wrap) => {
  if (window.outerWidth > 768) {
    const sections = wrap.current.querySelectorAll(".uniform-height-section");
    const sectionHt = [];
    let maxSectionHt = 0;
    sections.forEach((section) => {
      sectionHt.push(section.offsetHeight);
    });
    maxSectionHt = sectionHt.reduce((a, b) => {
      return Math.max(a, b);
    });
    sections.forEach((section) => {
      section.style.height = maxSectionHt + "px";
    });
  }
};
export const scrollHeaderHandler = (e) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
export const arraySearch = (array, keyword) => {
  const searchTerm = keyword.toLowerCase()
  return array.filter(value => {
    return value.name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.address.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.gender.toLowerCase().match(new RegExp(searchTerm, 'g'))
  })
}

export const initializePayload = async (page) => {
  const iframe = document.getElementById("ymIframe");
  const response = await fetch('https://extreme-ip-lookup.com/json/');
  const locations = await response.json();

  const payload = {
    event_code: "ym-client-event",
    data: {
      event: {
        code: "website",
        data: {
          page: page,
          name: null,
          phone: null,
          zipcode: null,
          city: locations.city,
          userAgent: navigator.userAgent,
          gaid: 'UA-202712703-1',
          countryCode: '+599'
        },
      },
    },
  };
  if (JSON.stringify(payload) !== window.ymConfig.payload) {
    iframe.contentWindow.postMessage(JSON.stringify(payload)); 
  }
}
