import React, { useState, useEffect, useRef } from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import logoColor from '../../../assets/images/logo-color.png';
import stickyLogo from '../../../assets/images/stickey-logo.png';
// import playStoreLogo from '../../../assets/images/play-store-icon.png';
// import appStoreLogo from '../../../assets/images/app-store.png';
import './Header.scss';

function Header() {
  let [navState, setnavState] = useState(false);
  // let [menuOpen,setMenuOpen]=useState(false);
  let [isMobileState, setisMobileState] = useState(false);
  const wrapperRef = useRef(null);
  const pathname = useLocation();

  useEffect(() => {
    if (pathname.pathname) {
      fixedNav();
    }
  }, [pathname]);

  // Detect outside click
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setnavState(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (document.querySelector('.backdrop-wrapper') !== null) {
      if (navState) {
        document.querySelector('.backdrop-wrapper').classList.add('isShow');
        document.querySelector('.backdrop-wrapper').classList.remove('isHide');
        document.querySelector('body').classList.add('overflow-y-hidden');
      } else {
        document.querySelector('.backdrop-wrapper').classList.remove('isShow');
        document.querySelector('.backdrop-wrapper').classList.add('isHide');
        document.querySelector('body').classList.remove('overflow-y-hidden');
      }
    }
  });

  // Fixed nav and menu close on scroll
  window.onscroll = (event) => {
    fixedNav();
    if (event.currentTarget.scrollY > 100) {
      setnavState(false);
    }
  };
  // Fixed nav function
  function fixedNav() {
    let header = document.getElementById('header-wrapper');
    let stickyTop = header.offsetTop;

    if (
      (window.pageYOffset >= stickyTop && window.pageYOffset > 10) ||
      pathname.pathname === '/keychain'
    ) {
      header.classList.add('stickey');
      header.style.top = '0px';
    } else if (window.pageYOffset === 0) {
      header.classList.remove('stickey');
      header.style.top = '0px';
    } else {
      header.classList.remove('stickey');
      header.style.top = '-106px';
    }
  }

  window.onload = checkScreenSize;

  // Check screen size for mobile site
  function checkScreenSize() {
    if (window.innerWidth < 992) {
      setisMobileState(true);
    } else {
      setisMobileState(false);
    }
  }

  window.addEventListener('resize', checkScreenSize);

  return (
    <div className='header-wrapper' id='header-wrapper'>
      <nav className={navState === true ? 'isOpen' : 'isClose'}>
        <div className='logo-wrapper'>
          <NavLink to='/home'>
            <img
              src={isMobileState === true ? stickyLogo : stickyLogo}
              className='isNotSticky'
              alt='Muvin'
            />
            <img
              effect='opacity'
              src={stickyLogo}
              className='isSticky'
              alt='Muvin'
            />
          </NavLink>
        </div>
        {/* <div className='menu-wrapper'>
          <div className='menu-wrapper-list'>
            {!isMobileState ? (
              <NavLink
                to='/parents'
                className='menu-wrapper-list-link'
                activeClassName='isActive'
              >
                <div className='menu-wrapper-list-item'> parents </div>
              </NavLink>
            ) : (
              ''
            )}
            {!isMobileState ? (
              <NavLink
                to='/children'
                className='menu-wrapper-list-link'
                activeClassName='isActive'
              >
                <div className='menu-wrapper-list-item'> children </div>
              </NavLink>
            ) : (
              ''
            )}
            <NavLink
              to='/finmania'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> finmania </div>
            </NavLink>
            <NavLink
              to='/about'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> about </div>
            </NavLink>
            <NavLink
              to='/partners'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> partners </div>
            </NavLink>
            <NavLink
              to='/personal-loan'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> loans </div>
            </NavLink>
            <NavLink
              to='/careers'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> careers </div>
            </NavLink>
            <NavLink
              to='/blog'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> blogs </div>
            </NavLink>
            {/* <NavLink
                            to="/offers/gamerfleet"
                            className="menu-wrapper-list-link"
                            activeClassName="isActive"
                        >
                            <div className="menu-wrapper-list-item">
                                {" "}
                                offers{" "}
                            </div>
                        </NavLink> */}
        {/* {isMobileState && (
              <div className='menu-wrapper-list-link get-app-message'>
                get the app now
              </div>
            )}
            <div className='menu-wrapper-list-link isIcon isSticky'>
              <a
                href='https://muvin.page.link/2bJC'
                className='menu-wrapper-list-item isAppIcon'
              >
                <LazyLoadImage
                  effect='opacity'
                  src={appStoreLogo}
                  alt='Muvin'
                />
              </a>
              <a
                href='https://muvin.page.link/Hbmt'
                className='menu-wrapper-list-item isAppIcon'
              >
                <LazyLoadImage
                  effect='opacity'
                  src={playStoreLogo}
                  alt='Muvin'
                />
              </a>
            </div>
          </div>
        </div> */}
        {/* {isMobileState ? (
          <div className='parent-child-wrapper '>
            <NavLink
              to='/parents'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> parents </div>
            </NavLink>
            <NavLink
              to='/children'
              className='menu-wrapper-list-link'
              activeClassName='isActive'
            >
              <div className='menu-wrapper-list-item'> children </div>
            </NavLink>
          </div>
        ) : (
          ''
        )} */}
        {/* <div
          ref={wrapperRef}
          className='hamburder-menu'
          onClick={() => setnavState((navState = !navState))}
        >
          <span className='hamburder-menu-bar'> </span>
          <span className='hamburder-menu-bar'> </span>
        </div> */}
      </nav>
    </div>
  );
}

export default Header;
