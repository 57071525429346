import loader from '../../../assets/images/loader_big.gif'
import './Loader.scss';

function Loader() {
  return (
      <div className="loader-wrapper">
          <img src={loader} alt="Muvin" className="img-fluid"/>
      </div>
  );
}

export default Loader;
