import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Home.scss';
import Banner from '../banner/Banner';
// import ChildrenSection from '../children-section/ChildrenSection';
import Loader from '../common/loader/Loader';
// import ArticlesSection from '../articles-section/ArticlesSection';
// import GatewaySection from '../gateway-section/GatewaySection';
// import VideoCarousel from '../video-carousel/VideoCarousel';
// import SocialSection from '../common/social-section/SocialSection';
// import FaqSection from '../faq-section/FaqSection';
// import OurStorySection from '../our-story-section/OurStorySection';
// import ParentsSection from '../parents-section/ParentsSection';
// import finmaniaImage from '../../assets/images/finmania.svg';

import {
  getData,
  getStaticDataFunc,
  getWindowWidth,
  initializePayload,
  setStaticDataFunc,
} from '../../common-service';
// import { Link } from 'react-router-dom';
// import { OurStoryPage } from '../about-us-component/OurStoryPage';

const query = `{
    page(id:"30d17K8uLfkFwVwXNq0QrF") {
      assetsCollection {
        items {
          fileName
          url
        }
      }
        articlesCollection (limit: 50) {
            items {
                ...on Section {
                    sectionLabel
                    titleMain
                    titleSub
                    description
                    imagesMobileCollection (limit: 50) {
                        items {
                            url
                        }
                    }
                    imagesCollection (limit: 50) {
                        items {
                            url
                        }
                    }
                    cardsCollection (limit: 50) {
                        items {
                              ... on CarouselModel {
                              carouselImage {
                                url
                              }
                            }
                            ... on Metrics {
                                metricLabel
                                metricValue
                                metricIcon {
                                    url
                                }
                            }
                            ... on Career {
                                position
                                vertical
                                location
                            }
                            ... on Faq {
                                query
                                description
                            }
                            ... on Testimonial {
                                name
                                description
                                customerImage {
                                    url
                                }
                            }
                            ...on Publication {
                                title
                                image {
                                url
                                }
                                url
                                }
                        }
                    }
                }
            }
        }
      videos{
          ...on BusinessVerticals {
              name
              verticals
          }
      }
    }
  }`;
const Home = (props) => {
  const checkBotLoading = setInterval(() => {
    if (document.getElementById('ymIframe')) {
      initializePayload('home');
      clearInterval(checkBotLoading);
    }
  }, 200);
  const [page, setPage] = useState(null);
  const [response, setResponse] = useState([]);

  const contentBody = {
    heading: 'our <b>partners</b> <br /> and <b>offers</b>',
    description: null,
    linkWrapper: {
      isLink: true,
      linkText: 'join us',
      linkHref: '/partners',
    },
  };

  let [isMobileState, setisMobileState] = useState(getWindowWidth);

  useEffect(() => {
    function handleResize() {
      setisMobileState(getWindowWidth);
    }

    setisMobileState(getWindowWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Data call to get the dynamic content
    getData(query)
      .then((res) => {
        setStaticDataFunc(res.data.data.page.assetsCollection.items);
        setPage(res.data.data.page.articlesCollection.items);
        setResponse(res.data.data.page.videos.verticals);
        setPage((prev) => {
          return prev.map((ele, idx) => {
            if (idx === 0) {
              return {
                ...ele,
                cardsCollection: {
                  items: ele?.cardsCollection?.items.map((singleImg) => ({
                    carouselImage: {
                      ...singleImg.carouselImage,
                      alt: 'Smart Banking for the Young',
                    },
                  })),
                },
              };
            }
            return ele;
          });
        });
      })
      .catch((err) => {
        console.error('Static content', err);
      });
  }, []);

  if (!page || getStaticDataFunc().length === 0) {
    return <Loader></Loader>;
  }
  // const videoUrl = response;
  return (
    <div className='w-100'>
      <Helmet>
        <title>
          muvin | Online Payment App For Teenage & Parents | Neobank
        </title>
        <meta
          name='description'
          content="India's best online payment app for teenagers is here! muvin provides safe and secure digital banking options for Kids, teenagers, and their parents."
        />
        <meta
          name='keywords'
          content='Best Neo Bank, Digital Bank, Digital Bank Account, Electronic Banks, Financial Management App, Insta Pay, Instant Pay App, Instant Payment App, Mobile Finance App, Neobank, Online Payment App, Online Pocket Money App, Payment App, Pocket Money APK, Pocket Money APK Download, Pocket Money Appc
Pocket Money App Download, Pocket Money Online, Smarter Banking, Muvin, Pocket Money App Free, Pocket Money Card'
        />
      </Helmet>
      {/* <Banner
        data={page[0]}
        gatewayData={page[1]}
        showGateway={false}
        isMobileState={isMobileState}
      ></Banner> */}
      <div className='text-container'>
        <div style={{ width: '75%' }}>
          <p className='hero-text'>
            muvin pre-paid program is closed
          </p>
          <div className='support-text'>
            Thank you for the support and we regret that muvin is unable to
            support your journey towards financial literacy
          </div>
          <div className='support-text'>
            Any available balance will be migrated to the Livquik app <br />{' '}
            <a href='/migrate'>Please click here to know more</a>
          </div>
        </div>
      </div>
      {/* <GatewaySection data={page[1]}></GatewaySection> */}
      {/* <div className="key-chain-widget-wrapper">
        <div className="key-chain-widget">
          <div className="key-chain-description">
            <h4 className="key-chain-title">
              participate in India’s biggest <br></br>
              finance olympiad & win <br></br>exciting prices
            </h4>
          </div>
          <div className="navigate-button">
            <div className="button-wrapper">
              <Link to="/finmania" className="btn btn-blue">
                <span className="text-left">know more</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="key-chain-image">
          <img alt="key-chainimage" src={finmaniaImage}></img>
        </div>
      </div> */}
      {/* <VideoCarousel data={videoUrl}></VideoCarousel> */}
      {/* <Metrics isMobileState={isMobileState} data={page[2]}></Metrics> */}
      {/* <ParentsSection isMobileState={isMobileState}></ParentsSection>
      <ChildrenSection isMobileState={isMobileState}></ChildrenSection> */}
      {/* <PartnersSection
        data={page[3]}
        contentBody={contentBody}
        isMobileState={isMobileState}
      ></PartnersSection> */}
      {/* <SecuritySection isMobileState={isMobileState}></SecuritySection> */}
      {/* <ArticlesSection
        data={page[4]}
        isMobileState={isMobileState}
      ></ArticlesSection> */}
      {/* <Testimonials data={page[5]} isMobileState={isMobileState}></Testimonials> */}
      {/* <PricingSection isMobileState={isMobileState}></PricingSection> */}
      {/* <OurStorySection isMobileState={isMobileState}></OurStorySection> */}
      {/* <Careers data={page[6]} isMobileState={isMobileState}></Careers> */}
      {/* <SocialSection
        data={page[7]}
        isMobileState={isMobileState}
      ></SocialSection> */}
      {/* <FaqSection data={page[8]} isMobileState={isMobileState}></FaqSection> */}
      {/* <ChatWithUs isMobileState={isMobileState}></ChatWithUs> */}
      {/* <OurStoryPage /> */}
    </div>
  );
};

export default Home;
