import React, { useState, useEffect } from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/common/header/Header';
import Home from './components/home/Home';
// import ParentsComponent from './components/parents-component/ParentsComponent';
import PageNotFound from './components/common/page-not-found/PageNotFound';
// import ChildrenComponent from './components/children-component/ChildrenComponent';
// import { OurStoryPage } from './components/about-us-component/OurStoryPage';
// import { PartnerPage } from './components/common/partners-page/PartnerPage';
// import CareersPage from './components/careers-page/CareersPage';
// import BlogPage from './components/blogs/blog-page/BlogPage';
// import BlogDescription from './components/blogs/blog-description/BlogDescription';
import Footer from './components/common/footer/Footer';
import { getWindowWidth } from './common-service';
import { PrivacyPolicy } from './components/common/privacy-policy/PrivacyPolicy';
import { TermsOfUses } from './components/common/terms-of-use/TermsOfUses';
import { FinoPrepaidCardTerms } from './components/common/prepaid-card-terms/FinoPrepaidCardTerms';
import { SavingsTermsOfUses } from './components/common/savings-terms-of-use/SavingsTOU';
// import { OffersGamerfleet } from "./components/offers/offers-gamerfleet/offersGamerfleet";
// import KeyChain from './components/key-chain/KeyChain';
// import Finmania from "./components/Finmania/Finmania";
import FinmaniaTnC from './components/common/finmania-tnc/FinmaniaTnC';
// import PersonalLoan from './components/loans/personal-loan/PersonalLoan';
import { IncredGrievance } from './components/common/IncredGrievance/IncredGrievance';
import MigrateSteps from './components/migrateSteps/MigrateSteps';

function App() {
  const mainWrapper = document.querySelector('.main-content');
  const backDrop = document.createElement('div');
  backDrop.classList.add('backdrop-wrapper');
  if (mainWrapper) {
    if (mainWrapper.querySelector('.backdrop-wrapper') === null) {
      mainWrapper.appendChild(backDrop);
    }
  }

  let [isMobileState, setisMobileState] = useState(getWindowWidth);

  useEffect(() => {
    function handleResize() {
      setisMobileState(getWindowWidth);
    }

    setisMobileState(getWindowWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <div className='App'>
        <div className='main-content'>
          <div className='main-wrapper'>
            <Header></Header>
            <Switch>
              <Route path='/' exact component={Home}></Route>
              <Route path='/index.html' component={Home} />
              <Route path='/home' component={Home}></Route>
              <Route path='/migrate' component={MigrateSteps}></Route>
              {/* <Route path='/parents' component={ParentsComponent}></Route>
              <Route path='/children' component={ChildrenComponent}></Route>
              <Route path='/about' component={OurStoryPage}></Route>
              <Route path='/partners' component={PartnerPage}></Route>
              <Route path='/careers' component={CareersPage}></Route>
              <Route path='/keychain' component={KeyChain}></Route> */}
              {/* <Route path="/finmania" component={Finmania}></Route> */}
              {/* <Route path='/blog' exact component={BlogPage}></Route>
              <Route path='/blog/:slug' component={BlogDescription}></Route> */}
              {/* <Route path="/offers" exact component={OffersPage}></Route>
                <Route path="/offers/:slug" component={OffersDescription}></Route> */}
              {/* <Route path='/privacy-policy' component={PrivacyPolicy}></Route>
              <Route path='/terms-of-use' component={TermsOfUses}></Route> */}
              {/* <Route path='/personal-loan' component={PersonalLoan}></Route> */}
              {/* <Route
                path='/savings-terms-of-use'
                component={SavingsTermsOfUses}
              /> */}
              {/* <Route
                path='/incred-customer-service-and-grievance-redressal-mechanism'
                component={IncredGrievance}
              /> */}
              {/* <Route
                path='/prepaidcard-terms'
                component={FinoPrepaidCardTerms}
              ></Route> */}
              {/* <Route path='/finmaniaterms' component={FinmaniaTnC}></Route> */}
              {/* <Route
                path="/offers/gamerfleet"
                component={OffersGamerfleet}
              ></Route> */}
              <Route path='*' component={PageNotFound}></Route>
            </Switch>
            {/* <Footer isMobileState={isMobileState}></Footer> */}
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
